<template>
  <div class="main container product-page">
    <Breadcrumb :list="breadcrumbList" />
    <div class="content">
      <div class="left">
        <h2 class="title">设计理念</h2>
        <section>
          <p class="animate__animated animate__fadeIn">
            ShuangzhaoDB的设计动力源于软件系统对于数据的时效性要求越来越高、越来越迫切，而传统数据库由于架构陈旧、数据在本地文件保存，所以不论并发还是处理速度，都难以满足要求。而新型的NoSQL数据库，又缺乏SQL支持以及完整的ACID和事务的支持，完全无法替代传统数据库。
          </p>
        </section>
        <section>
          <p class="animate__animated animate__fadeIn">
            ShuangzhaoDB、NoSQL和传统关系型数据库的对比如下所示：ShuangzhaoDB采用类MPP架构(Massive-ParallelProcessing海量并行处理架构)，完全在内存中运行，提供定期生成磁盘快照的选择。采用横向可扩展的架构。传统的关系数据库将数据写入基于磁盘的日志文件。ShuangzhaoDB则不然，是同时对内存内的多台机器进行修改。例如，即使两台机器发生故障，KSafety系数为2时即可保证不会造成数据损失，因为数据至少存入三台服务器内存。
          </p>
        </section>
        <section>
          <p class="animate__animated animate__fadeIn">
            事务作为存储过程(storedprocedure)提交，可在数据库中异步执行，并且数据自动分区（分片），分配至系统内的节点，尽管可复制维表数据以最大限度地提高连接性能。
          </p>
        </section>
      </div>
      <ProdMenu class="right hidden-sm-and-down" activeIndex="1" />
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import ProdMenu from "@/components/ProdMenu";
import "animate.css";
export default {
  name: "DesignConcept",
  components: {
    Breadcrumb,
    ProdMenu,
  },
  data() {
    return {
      breadcrumbList: [
        { id: 1, path: "/", label: "ShuangzhaoDB" },
        { id: 2, path: "/product", label: "产品" },
        { id: 3, path: "", label: "设计理念" },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
@import "~@/styles/product.less";
</style>